<template>
  <div>
    <YouthPathfinders />
    <YouthMissions />
    <YouthChoir />
    <EventsStudy />
    <GetInTouch />
    <YouthStudy />
  </div>
</template>

<script>
import YouthPathfinders from '@/components/youth/YouthPathfinders.vue'
import YouthMissions from '@/components/youth/YouthMissions.vue'
import YouthChoir from '@/components/youth/YouthChoir.vue'
import EventsStudy from '@/components/events/EventsStudy.vue'
import GetInTouch from '@/components/footer/GetInTouch.vue'
import YouthStudy from '@/components/youth/YouthStudy.vue'

export default {
  components: {
    YouthPathfinders,
    YouthMissions,
    YouthChoir,
    EventsStudy,
    GetInTouch,
    YouthStudy
  },
  head: {
    title: function () {
      return {
        inner: 'Youth'
      }
    }
  }
}
</script>
