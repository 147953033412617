<template>
  <v-container>
    <p>
      Toronto Central Seventh-day Adventist Church is committed to protecting your privacy and developing technology
      that gives you the most powerful and safe online experience. This Statement of Privacy
      applies to the Toronto Central Seventh-day Adventist Church Web site and governs data collection and usage.
      By using the Toronto Central Seventh-day Adventist Church website, you consent to the data practices described
      in this statement.
    </p>
    <p><span class="SubHead">Collection of your Personal Information</span></p>
    <p>
      Toronto Central Seventh-day Adventist Church collects personally identifiable information, such as your e-mail
      address, name, home or work address or telephone number. Toronto Central Seventh-day Adventist Church also
      collects anonymous demographic information, which is not unique to you, such as
      your ZIP code, age, gender, preferences, interests and favorites.
    </p>
    <p>
      There is also information about your computer hardware and software that is automatically
      collected by Toronto Central Seventh-day Adventist Church. This information can include: your IP address,
      browser type, domain names, access times and referring Web site addresses. This
      information is used by Toronto Central Seventh-day Adventist Church for the operation of the service, to
      maintain quality of the service, and to provide general statistics regarding use
      of the Toronto Central Seventh-day Adventist Church Web site.
    </p>
    <p>
      Please keep in mind that if you directly disclose personally identifiable information
      or personally sensitive data through Toronto Central Seventh-day Adventist Church public message boards,
      this information may be collected and used by others. Note: Toronto Central Seventh-day Adventist Church
      does not read any of your private online communications.
    </p>
    <p>
      Toronto Central Seventh-day Adventist Church encourages you to review the privacy statements of Web sites
      you choose to link to from Toronto Central Seventh-day Adventist Church so that you can understand how those
      Web sites collect, use and share your information. Toronto Central Seventh-day Adventist Church is not responsible
      for the privacy statements or other content on Web sites outside of the Toronto Central Seventh-day Adventist Church
      and Toronto Central Seventh-day Adventist Church family of Web sites.
    </p>
    <p><span class="SubHead">Use of your Personal Information</span></p>
    <p>
      Toronto Central Seventh-day Adventist Church collects and uses your personal information to operate the Toronto Central Seventh-day Adventist Church
      Web site and deliver the services you have requested. Toronto Central Seventh-day Adventist Church also uses
      your personally identifiable information to inform you of other products or services
      available from Toronto Central Seventh-day Adventist Church and its affiliates. Toronto Central Seventh-day Adventist Church may also
      contact you via surveys to conduct research about your opinion of current services
      or of potential new services that may be offered.
    </p>
    <p>
      Toronto Central Seventh-day Adventist Church does not sell, rent or lease its customer lists to third parties.
      Toronto Central Seventh-day Adventist Church may, from time to time, contact you on behalf of external business
      partners about a particular offering that may be of interest to you. In those cases,
      your unique personally identifiable information (e-mail, name, address, telephone
      number) is not transferred to the third party. In addition, Toronto Central Seventh-day Adventist Church
      may share data with trusted partners to help us perform statistical analysis, send
      you email or postal mail, provide customer support, or arrange for deliveries. All
      such third parties are prohibited from using your personal information except to
      provide these services to Toronto Central Seventh-day Adventist Church, and they are required to maintain
      the confidentiality of your information.
    </p>
    <p>
      Toronto Central Seventh-day Adventist Church does not use or disclose sensitive personal information, such
      as race, religion, or political affiliations, without your explicit consent.
    </p>
    <p>
      Toronto Central Seventh-day Adventist Church keeps track of the Web sites and pages our customers visit within
      Toronto Central Seventh-day Adventist Church, in order to determine what Toronto Central Seventh-day Adventist Church services are
      the most popular. This data is used to deliver customized content and advertising
      within Toronto Central Seventh-day Adventist Church to customers whose behavior indicates that they are interested
      in a particular subject area.
    </p>
    <p>
      Toronto Central Seventh-day Adventist Church Web sites will disclose your personal information, without notice,
      only if required to do so by law or in the good faith belief that such action is
      necessary to: (a) conform to the edicts of the law or comply with legal process
      served on Toronto Central Seventh-day Adventist Church or the site; (b) protect and defend the rights or
      property of Toronto Central Seventh-day Adventist Church; and, (c) act under exigent circumstances to protect
      the personal safety of users of Toronto Central Seventh-day Adventist Church, or the public.
    </p>
    <p><span class="SubHead">Use of Cookies</span></p>
    <p>
      The Toronto Central Seventh-day Adventist Church Web site use "cookies" to help you personalize your online
      experience. A cookie is a text file that is placed on your hard disk by a Web page
      server. Cookies cannot be used to run programs or deliver viruses to your computer.
      Cookies are uniquely assigned to you, and can only be read by a web server in the
      domain that issued the cookie to you.
    </p>
    <p>
      One of the primary purposes of cookies is to provide a convenience feature to save
      you time. The purpose of a cookie is to tell the Web server that you have returned
      to a specific page. For example, if you personalize Toronto Central Seventh-day Adventist Church pages, or
      register with Toronto Central Seventh-day Adventist Church site or services, a cookie helps Toronto Central Seventh-day Adventist Church
      to recall your specific information on subsequent visits. This simplifies the process
      of recording your personal information, such as billing addresses, shipping addresses,
      and so on. When you return to the same Toronto Central Seventh-day Adventist Church Web site, the information
      you previously provided can be retrieved, so you can easily use the Toronto Central Seventh-day Adventist Church
      features that you customized.
    </p>
    <p>
      You have the ability to accept or decline cookies. Most Web browsers automatically
      accept cookies, but you can usually modify your browser setting to decline cookies
      if you prefer. If you choose to decline cookies, you may not be able to fully experience
      the interactive features of the Toronto Central Seventh-day Adventist Church services or Web sites you visit.
    </p>
    <p><span class="SubHead">Security of your Personal Information</span></p>
    <p>
      Toronto Central Seventh-day Adventist Church secures your personal information from unauthorized access,
      use or disclosure. Toronto Central Seventh-day Adventist Church secures the personally identifiable information
      you provide on computer servers in a controlled, secure environment, protected from
      unauthorized access, use or disclosure. When personal information (such as a credit
      card number) is transmitted to other Web sites, it is protected through the use
      of encryption, such as the Secure Socket Layer (SSL) protocol.
    </p>
    <p><span class="SubHead">Changes to this Statement</span></p>
    <p>
      Toronto Central Seventh-day Adventist Church will occasionally update this Statement of Privacy to reflect
      company and customer feedback. Toronto Central Seventh-day Adventist Church encourages you to periodically
      review this Statement to be informed of how Toronto Central Seventh-day Adventist Church is protecting your
      information.
    </p>
    <p><span class="SubHead">Contact Information</span></p>
    <p>
      Toronto Central Seventh-day Adventist Church welcomes your comments regarding this Statement of Privacy.
      If you believe that Toronto Central Seventh-day Adventist Church has not adhered to this Statement, please
      contact Toronto Central Seventh-day Adventist Church at <a href="mailto:Toronto Centralclerk@adventistontario.org">Toronto Centralclerk@adventistontario.org</a>.
      We will use commercially reasonable efforts to promptly determine and remedy the
      problem.
    </p>
  </v-container>
</template>

<script>
export default {
  name: 'PrivacyContent'
}
</script>
