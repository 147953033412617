<template>
  <div>
    <MissionsCS />
    <MissionsADRA />
    <MissionsYouth />
    <ChildrensStudy />
    <GetInTouch />
    <MissionsStudy />
  </div>
</template>

<script>
import MissionsCS from '@/components/missions/MissionsCS.vue'
import MissionsADRA from '@/components/missions/MissionsADRA.vue'
import MissionsYouth from '@/components/missions/MissionsYouth.vue'
import ChildrensStudy from '@/components/childrens/ChildrensStudy.vue'
import GetInTouch from '@/components/footer/GetInTouch.vue'
import MissionsStudy from '@/components/missions/MissionsStudy.vue'

export default {
  components: {
    MissionsCS,
    MissionsADRA,
    MissionsYouth,
    ChildrensStudy,
    GetInTouch,
    MissionsStudy
  },
  head: {
    title: function () {
      return {
        inner: 'Missions'
      }
    }
  }
}
</script>
