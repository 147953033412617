<template>
  <div>
    <EventsParallax />
    <EventsCurrent />
    <EventsStudy />
    <EventsPast id="past" />
    <GetInTouch id="intouch" />
  </div>
</template>

<script>
import EventsParallax from '@/components/events/EventsParallax.vue'
import EventsCurrent from '@/components/events/EventsCurrent.vue'
import EventsStudy from '@/components/events/EventsStudy.vue'
import EventsPast from '@/components/events/EventsPast.vue'
import GetInTouch from '@/components/footer/GetInTouch.vue'

export default {
  components: {
    EventsParallax,
    EventsCurrent,
    EventsStudy,
    EventsPast,
    GetInTouch
  },
  head: {
    title: function () {
      return {
        inner: 'Events'
      }
    }
  }
}
</script>
