<template>
  <div>
    <MinistriesMens />
    <MinistriesWomens />
    <MinistriesHealth />
    <MinistriesPrayer />
    <MinistriesFamily />
    <MinistriesSingles />
    <GetConnected />
    <EventsStudy />
    <GetInTouch />
  </div>
</template>

<script>
import MinistriesMens from '@/components/ministries/MinistriesMens.vue'
import MinistriesWomens from '@/components/ministries/MinistriesWomens.vue'
import MinistriesHealth from '@/components/ministries/MinistriesHealth.vue'
import MinistriesPrayer from '@/components/ministries/MinistriesPrayer.vue'
import MinistriesFamily from '@/components/ministries/MinistriesFamily.vue'
import MinistriesSingles from '@/components/ministries/MinistriesSingles.vue'
import GetConnected from '@/components/GetConnected.vue'
import EventsStudy from '@/components/events/EventsStudy.vue'
import GetInTouch from '@/components/footer/GetInTouch.vue'

export default {
  components: {
    MinistriesMens,
    MinistriesWomens,
    MinistriesHealth,
    MinistriesPrayer,
    MinistriesFamily,
    MinistriesSingles,
    GetConnected,
    EventsStudy,
    GetInTouch
  },
  head: {
    title: function () {
      return {
        inner: 'Ministries'
      }
    }
  }
}
</script>
