<template>
  <div>
    <v-img
      v-if="$vuetify.breakpoint.lgAndUp"
      dark
      src="@/assets/images/home/AboutBG.jpg"
      eager
      height="780"
    >
      <v-overlay absolute opacity="0.1">
        <v-row
          v-if="$vuetify.breakpoint.lgAndUp"
          justify="center"
        >
          <v-col cols="3"></v-col>
          <v-col
            class="text-left"
            cols="6"
          >
            <v-card
              class="px-10 py-10"
              style="background-color:white"
              height="780"
            >
              <v-card-title class="font-weight-bold text-h3" style="color:#425C7F; word-break: keep-all;">TORONTO CENTRAL</v-card-title>
              <v-card-subtitle class="text-h5 font-weight-regular" style="color:#425C7F">WHO WE ARE</v-card-subtitle>
              <v-card-text class="mb-3" style="color:#425C7F">
                <h3 class="font-weight-regular mb-3">
                  At Toronto Central, fellowship is of utmost importance to us. The smiles, the hugs, the warm greetings at the doors of our sanctuary from our exceptional team of ushers truly make you feel welcome and at home.
                </h3>
                <h3 class="font-weight-regular mb-3">
                  Fellowship is so important to us that we have made the effort to create a special committee with the sole responsibility of planning the church’s fellowship activities, yes that’s how serious we are about fellowship at TC.
                </h3>
                <h3 class="font-weight-regular mb-10">
                  To learn more about ministries that serve you, click the link below.
                </h3>
              </v-card-text>
              <div class="text-center">
                <v-btn
                  large
                  rounded
                  href="/about"
                  class="px-10 font-weight-regular"
                  color="primary"
                >
                  <div style="color:white">LEARN MORE</div>
                </v-btn>
              </div>
            </v-card>
          </v-col>
          <v-col cols="3"></v-col>
        </v-row>
      </v-overlay>
    </v-img>
    
    <v-card
      v-if="$vuetify.breakpoint.mdAndDown"  
      class="py-10"
      style="background-color:white"
    >
      <v-card-title class="font-weight-bold text-h3" style="color:#425C7F; word-break: keep-all;">TORONTO CENTRAL</v-card-title>
      <v-card-subtitle class="text-h5 font-weight-regular" style="color:#425C7F">WHO WE ARE</v-card-subtitle>
      <v-card-text class="mb-3" style="color:#425C7F">
        <h3 class="font-weight-regular mb-3">
          At Toronto Central, fellowship is of utmost importance to us. The smiles, the hugs, the warm greetings at the doors of our sanctuary from our exceptional team of ushers truly make you feel welcome and at home.
        </h3>
        <h3 class="font-weight-regular mb-3">
          Fellowship is so important to us that we have made the effort to create a special committee with the sole responsibility of planning the church’s fellowship activities, yes that’s how serious we are about fellowship at TC.
        </h3>
        <h3 class="font-weight-regular mb-10">
          To learn more about ministries that serve you, click the link below.
        </h3>
      </v-card-text>
      <div class="text-center">
        <v-btn
          large
          rounded
          href="/about"
          class="font-weight-regular"
          color="primary"
        >
          <div style="color:white">LEARN MORE</div>
        </v-btn>
      </div>
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'HomeAbout'
}
</script>
