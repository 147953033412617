<template>  
  <v-card 
    class="rounded-0 pt-4" 
    color="grey darken-3" 
    style="background-image: url('/images/home/WatchLiveBG.jpg')">
    <v-row class="d-flex justify-center">
      <v-container>
        <div class="video-container">
          <iframe
            class="responsive-iframe"
            src="https://www.youtube.com/embed/live_stream?channel=UCuOF0pHZU_3mIKzAz46ESAw&amp;autoplay=1"
            frameborder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen=""
          ></iframe>
        </div>
      </v-container>
    </v-row>
    <v-row class="text-center pb-4">
      <v-container>
        <v-btn
          large
          rounded
          elevation="4"
          href="https://www.youtube.com/TorontoCentralSDA"
          target="_blank"
          color="#F44D40"
          class="px-10 font-weight-regular"
        >
          <div style="color:white">MORE VIDEOS</div>
        </v-btn>
      </v-container>
    </v-row>
  </v-card>
</template>

<script>
export default {
  name: 'HomeLive'
}
</script>
