<template>
  <div>
    <SportsCYSA />
    <SportsVolleyball />
    <SportsBasketball />
    <SportsSoccer />
    <GetInTouch />
    <ChildrensStudy />
  </div>
</template>

<script>
import SportsCYSA from '@/components/sports/SportsCYSA.vue'
import SportsVolleyball from '@/components/sports/SportsVolleyball.vue'
import SportsBasketball from '@/components/sports/SportsBasketball.vue'
import SportsSoccer from '@/components/sports/SportsSoccer.vue'
import GetInTouch from '@/components/footer/GetInTouch.vue'
import ChildrensStudy from '@/components/childrens/ChildrensStudy.vue'

export default {
  components: {
    SportsCYSA,
    SportsVolleyball,
    SportsBasketball,
    SportsSoccer,
    GetInTouch,
    ChildrensStudy
  },
  head: {
    title: function () {
      return {
        inner: 'Sports'
      }
    }
  }
}
</script>
