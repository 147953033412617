import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VImg,{attrs:{"light":"","src":require("@/assets/images/childrens/BibleStudyBG.jpg")}},[_c(VOverlay,{attrs:{"absolute":"","opacity":"0.2"}},[_c(VContainer,{staticClass:"py-15"},[_c(VRow,{attrs:{"align":"center","justify":"center"}},[_c(VCol,{staticClass:"text-center",attrs:{"cols":"12"}},[_c('h1',{staticClass:"text-h4 mb-4 font-weight-regular",staticStyle:{"color":"white"}},[_vm._v(" Join a group or one-on-one bible study session ")]),_c(VBtn,{staticClass:"px-10 font-weight-regular",attrs:{"large":"","rounded":"","elevation":"4","color":"#F44D40"}},[_c('div',{staticStyle:{"color":"white"}},[_vm._v("SIGN UP TODAY")])])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }