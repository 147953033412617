<template>
  <div>
    <AboutParallax />
    <MottoMission />
    <AboutBeliefs />
    <!-- <AboutPastors /> -->
    <AboutNew id="iamnew" />
    <!-- <AboutNembhard />
    <AboutDarius />
    <AboutBlake />
    <AboutGreen />
    <AboutNew id="iamnew" /> -->
    <!-- <AboutQuote />
    <AboutResources /> -->
    <GetInTouch id="getintouch" />
  </div>
</template>

<script>
import AboutParallax from '@/components/about/AboutParallax.vue'
import MottoMission from '@/components/about/MottoMission.vue'
import AboutBeliefs from '@/components/about/AboutBeliefs.vue'
// import AboutPastors from '@/components/about/AboutPastors.vue'
// import AboutNembhard from '@/components/about/AboutNembhard.vue'
// import AboutDarius from '@/components/about/AboutDarius.vue'
// import AboutBlake from '@/components/about/AboutBlake.vue'
// import AboutGreen from '@/components/about/AboutGreen.vue'
import AboutNew from '@/components/about/AboutNew.vue'
// import AboutQuote from '@/components/about/AboutQuote.vue'
// import AboutResources from '@/components/about/AboutResources.vue'
import GetInTouch from '@/components/footer/GetInTouch.vue'

export default {
  components: {
    AboutParallax,
    MottoMission,
    AboutBeliefs,
    // AboutPastors,
    AboutNew,
    // AboutNembhard,
    // AboutDarius,
    // AboutBlake,
    // AboutGreen,
    // AboutNew,
    // AboutQuote,
    // AboutResources,
    GetInTouch
  },
  head: {
    title: function () {
      return {
        inner: 'About'
      }
    }
  }
}
</script>
