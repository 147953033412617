<template>
  <div>
    <FormsPA />
  </div>
</template>

<script>
import FormsPA from '@/components/forms/FormsPA.vue'

export default {
  components: {
    FormsPA
  },
  head: {
    title: function () {
      return {
        inner: 'Forms'
      }
    }
  }
}
</script>
