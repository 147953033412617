<template>
  <div style="background-color:#EFEFEF">
    <v-container class="py-12">
      <v-row class="text-center">
        <v-col cols="12">
          <h1 class="display-2 mb-4 mt-0 font-weight-bold text-center" style="color:#707070">
            CURRENT EVENTS
          </h1>
        </v-col>
      </v-row>
      <v-row
        class="mb-10"
      >
        <v-col cols="1"></v-col>
        <v-col cols="6">
          <img src="@/assets/images/events/2021_07-TCentral-IfMyPeoplePray2.jpg" width="100%" class="mb-2" />
          <v-btn
            icon
            href="/"
            style="background-color:#F44D40"
            class="mr-1"
            target="_blank"
          >
            <v-icon color="white">mdi-share-variant</v-icon>
          </v-btn>
          <v-btn
            icon
            href="/"
            style="background-color:#F44D40"
            class="mr-1"
            target="_blank"
          >
            <v-icon color="white">mdi-facebook</v-icon>
          </v-btn>
          <v-btn
            icon
            href="/"
            style="background-color:#F44D40"
            target="_blank"
          >
            <v-icon color="white">mdi-whatsapp</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="4">
          <h1 class="font-weight-black mb-2" style="color:#707070">IF MY PEOPLE PRAY</h1>
          <h3 class="font-weight-medium mb-1" style="color:black">Wednesday Nights at 7:15 PM</h3>
          <h3 class="font-weight-medium mb-2" style="color:black">Sept. 02, 2020 - Oct. 14, 2020</h3>
          <p style="color:#707070">Toronto Central Presents “If My People Pray” hosting many dynamic speakers from around the globe.</p>
          <p style="color:#707070">Zoom ID: 727417758</p>
          <p style="color:#707070">Zoom Passcode: 322811</p>
        </v-col>
        <v-col cols="1"></v-col>
      </v-row>
      <v-row
        class="mt-10"
      >
        <v-col cols="1"></v-col>
        <v-col cols="6">
          <img src="@/assets/images/events/2021_07_24_Music Day_Flyer.jpg" width="100%" class="mb-2" />
          <v-btn
            icon
            href="/"
            style="background-color:#F44D40"
            class="mr-1"
            target="_blank"
          >
            <v-icon color="white">mdi-share-variant</v-icon>
          </v-btn>
          <v-btn
            icon
            href="/"
            style="background-color:#F44D40"
            class="mr-1"
            target="_blank"
          >
            <v-icon color="white">mdi-facebook</v-icon>
          </v-btn>
          <v-btn
            icon
            href="/"
            style="background-color:#F44D40"
            target="_blank"
          >
            <v-icon color="white">mdi-whatsapp</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="4">
          <h1 class="font-weight-black mb-2" style="color:#707070">BETWEEN THE LINES</h1>
          <h3 class="font-weight-medium mb-2" style="color:black">Starting May 31, 2020</h3>
          <p style="color:#707070">Youth between the ages of 12 - 25 join us for a virtual rap session of the in depth discussion of our first book: Messages to Young People. The PDF of this book can be found online.</p>
          <h3 class="font-weight-medium mb-4" style="color:black">Every Sunday at 1 PM - 2 PM</h3>
          <v-btn
            large
            rounded
            elevation="4"
            color="#425C80"
            class="px-8 font-weight-regular"
          >
            <div style="color:white">SIGN UP & GET A REMINDER</div>
          </v-btn>
        </v-col>
        <v-col cols="1"></v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: 'EventsCurrent'
}
</script>
