<template>
  <v-img
    dark
    src="@/assets/images/home/GetConnectedBG.jpg"
  >
    <v-overlay absolute opacity="0.2">
      <h1 class="text-center text-h3 mb-5">GET CONNECTED</h1>
      <v-row>
        <v-col cols="1" class="mr-10"></v-col>
        <v-col
          class="text-center"
          cols="3"
        >
          <v-card
            class="mx-auto"
            max-width="300"
          >
            <v-img
              class="primary--text align-center"
              height="250px"
              src="@/assets/images/placeholder2.jpg"
            >
              <v-card-title
                style="background-color:white"
                class="mx-2 font-weight-bold text-center"
              >
                CHILDREN'S CORNER
              </v-card-title>
            </v-img>
          </v-card>
        </v-col>
        <v-col
          class="text-center"
          cols="3"
        >
          <v-card
            class="mx-auto"
            max-width="300"
          >
            <v-img
              class="primary--text align-center"
              height="250px"
              src="@/assets/images/placeholder2.jpg"
            >
              <v-card-title
                style="background-color:white"
                class="mx-2 font-weight-bold text-center"
              >
                YOUNG & YOUTH ADULTS</v-card-title>
            </v-img>
          </v-card>
        </v-col>
        <v-col
          class="text-center"
          cols="3"
        >
          <v-card
            class="mx-auto"
            max-width="300"
          >
            <v-img
              class="primary--text align-center"
              height="250px"
              src="@/assets/images/placeholder2.jpg"
            >
              <v-card-title
                style="background-color:white"
                class="mx-2 font-weight-bold text-center"
              >
                ADULTS & SENIORS</v-card-title>
            </v-img>
          </v-card>
        </v-col>
        <v-col cols="1"></v-col>
      </v-row>
      <v-row>
        <v-col cols="1" class="mr-10"></v-col>
        <v-col
          class="text-center"
          cols="3"
        >
          <v-card
            class="mx-auto"
            max-width="300"
          >
            <v-img
              class="primary--text align-center"
              height="250px"
              src="@/assets/images/placeholder2.jpg"
            >
              <v-card-title
                style="background-color:white"
                class="mx-2 font-weight-bold text-center"
              >
                SPORTS</v-card-title>
            </v-img>
          </v-card>
        </v-col>
        <v-col
          class="text-center"
          cols="3"
        >
          <v-card
            class="mx-auto"
            max-width="300"
          >
            <v-img
              class="primary--text align-center"
              height="250px"
              src="@/assets/images/placeholder2.jpg"
            >
              <v-card-title
                style="background-color:white"
                class="mx-2 font-weight-bold text-center"
              >
                MISSIONS</v-card-title>
            </v-img>
          </v-card>
        </v-col>
        <v-col
          class="text-center"
          cols="3"
        >
          <v-card
            class="mx-auto"
            max-width="300"
          >
            <v-img
              class="primary--text align-center"
              height="250px"
              src="@/assets/images/placeholder2.jpg"
            >
              <v-card-title
                style="background-color:white"
                class="mx-2 font-weight-bold text-center"
              >
                EVENTS</v-card-title>
            </v-img>
          </v-card>
        </v-col>
        <v-col cols="1"></v-col>
      </v-row>
      <v-row>
        <v-col cols="1" class="mr-10"></v-col>
        <v-col
          class="text-center"
          cols="3"
        >
          <v-card
            class="mx-auto"
            max-width="300"
          >
            <v-img
              class="primary--text align-center"
              height="250px"
              src="@/assets/images/placeholder2.jpg"
            >
              <v-card-title
                style="background-color:white"
                class="mx-2 font-weight-bold text-center"
              >
                OTHER MINISTRIES</v-card-title>
            </v-img>
          </v-card>
        </v-col>
        <v-col
          class="text-center"
          cols="3"
        >
          <v-card
            class="mx-auto"
            max-width="300"
          >
            <v-img
              class="primary--text align-center"
              height="250px"
              src="@/assets/images/placeholder2.jpg"
            >
              <v-card-title
                style="background-color:white"
                class="mx-2 font-weight-bold text-center"
              >
                HEALTH</v-card-title>
            </v-img>
          </v-card>
        </v-col>
        <v-col
          class="text-center"
          cols="3"
        >
          <v-card
            class="mx-auto"
            max-width="300"
          >
            <v-img
              class="primary--text align-center"
              height="250px"
              src="@/assets/images/placeholder2.jpg"
            >
              <v-card-title
                style="background-color:white"
                class="mx-2 font-weight-bold text-center"
              >
                RESOURCES</v-card-title>
            </v-img>
          </v-card>
        </v-col>
        <v-col cols="1"></v-col>
      </v-row>
      <v-row>
        <v-col cols="1" class="mr-10"></v-col>
        <v-col
          class="text-center"
          cols="3"
        >
          <v-card
            class="mx-auto"
            max-width="300"
          >
            <v-img
              class="primary--text align-center"
              height="250px"
              src="@/assets/images/placeholder2.jpg"
            >
              <v-card-title
                style="background-color:white"
                class="mx-2 font-weight-bold text-center"
              >
                PRAYER REQUEST</v-card-title>
            </v-img>
          </v-card>
        </v-col>
        <v-col
          class="text-center"
          cols="3"
        >
          <v-card
            class="mx-auto"
            max-width="300"
          >
            <v-img
              class="primary--text align-center"
              height="250px"
              src="@/assets/images/placeholder2.jpg"
            >
              <v-card-title
                style="background-color:white"
                class="mx-2 font-weight-bold text-center"
              >
                DECISION FOR CHRIST</v-card-title>
            </v-img>
          </v-card>
        </v-col>
        <v-col
          class="text-center"
          cols="3"
        >
          <v-card
            class="mx-auto"
            max-width="300"
          >
            <v-img
              class="primary--text align-center"
              height="250px"
              src="@/assets/images/placeholder2.jpg"
            >
              <v-card-title
                style="background-color:white"
                class="mx-2 font-weight-bold text-center"
              >
                INFANT & <br />CHILD DEDICATION</v-card-title>
            </v-img>
          </v-card>
        </v-col>
        <v-col cols="1"></v-col>
      </v-row>
    </v-overlay>
  </v-img>
</template>

<script>
export default {
  name: 'GetConnected'
}
</script>
