<template>
  <div>
    <PrayerRequest />
  </div>
</template>
  
<script>
import PrayerRequest from '@/components/PrayerRequest.vue'

export default {
  components: {
    PrayerRequest
  },
  head: {
    title: function () {
      return {
        inner: 'Prayer Request'
      }
    }
  }
}
</script>