<template>
  <v-img
    dark
    src="@/assets/images/watch/WatchHeader.jpg"
  >
    <v-overlay absolute opacity="0.1">
      <v-container>
        <v-row
          align="center"
          justify="center"
        >

          <v-col
            class="text-center"
            cols="12"
            elevation-2
          >
            <iframe width="720" height="480" src="https://www.youtube.com/embed/live_stream?channel=UCuOF0pHZU_3mIKzAz46ESAw&amp;autoplay=1" frameborder="0"></iframe>
            <h2 class="mt-2 font-wieght-medium">FROM BITTER TO BETTER WITH JOEL NEMBHARD</h2>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="12" class="text-center">
            <v-btn
              large
              rounded
              elevation="4"
              color="#F44D40"
              class="px-10 font-weight-regular"
              href="https://www.youtube.com/channel/UCuOF0pHZU_3mIKzAz46ESAw"
              target="_blank"
            >
              <div style="color:white">VISIT OUR YOUTUBE</div>
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-overlay>
  </v-img>
</template>

<script>
export default {
  name: 'WatchParallax'
}
</script>
