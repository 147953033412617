<template>
  <div>
    <AdultsHeader />
    <SeniorsMinistries />
    <SeniorsChoir />
    <AdultsPrayer />
    <GetInTouch />
    <AboutResources />
  </div>
</template>

<script>
import AdultsHeader from '@/components/adults/AdultsHeader.vue'
import SeniorsMinistries from '@/components/adults/SeniorsMinistries.vue'
import SeniorsChoir from '@/components/adults/SeniorsChoir.vue'
import AdultsPrayer from '@/components/adults/AdultsPrayer.vue'
import GetInTouch from '@/components/footer/GetInTouch.vue'
import AboutResources from '@/components/about/AboutResources.vue'

export default {
  components: {
    AdultsHeader,
    SeniorsMinistries,
    SeniorsChoir,
    AdultsPrayer,
    GetInTouch,
    AboutResources
  },
  head: {
    title: function () {
      return {
        inner: 'Adults'
      }
    }
  }
}
</script>
