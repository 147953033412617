<template>
  <v-img
    dark
    eager
    src="@/assets/images/events/EventsHeader.jpg"
  >
    <v-overlay absolute opacity="0.2">
    </v-overlay>
  </v-img>
</template>

<script>
export default {
  name: 'EventsParallax'
}
</script>
