<template>
  <div style="background-image: url('/images/about/NewBG.jpg'); background-size:cover; background-position:center; color: white;" class="pt-6">
    <v-container>
      <v-row>
        <v-col cols="12">
          <h1 class="display-2 text-center font-weight-bold">I'M NEW</h1>
        </v-col>
      </v-row>

      <v-row
        justify="left"
      >
        <v-col 
          v-if="$vuetify.breakpoint.mdAndUp"
          cols="2" 
          class="mr-8"
        >
        </v-col>
        <v-col
          sm12 
          md7
        >
          <h1 class="text-h4 mb-3">
            WHAT TO EXPECT
          </h1>
          <h3 class="font-weight-regular mb-3">Expect to be welcomed with opened arms, expect wonderful fellowship, expect to meet people who are happy and Christ-centered.</h3>
          <h3 class="font-weight-regular mb-3">You can expect beautiful singing that will uplift you. You can expect to hear the Word of God delivered by his chosen vessels.</h3>
          <h3 class="font-weight-regular mb-3">You can expect to be blessed.</h3>
          <h1 class="text-h4 mt-10 mb-3">
            WHERE TO PARK
          </h1>
          <h3 class="font-weight-regular mb-3">Our newly renovated parking lot provides ample parking space on the church grounds to the rear of the building. Simply use the west driveway to enter and use the east driveway to make your exit.</h3>
          <h1 class="text-h4 mt-10 mb-3">
            HOW SHOULD I DRESS?
          </h1>
          <h3 class="font-weight-regular mb-3">Most people wear formal or semi-formal attire. However, wear whatever makes you feel comfortable. We will welcome you as you are.</h3>
          <h3 class="font-weight-regular mb-3">We want you to feel comfortable and at home.</h3>
        </v-col>
        <v-col 
          v-if="$vuetify.breakpoint.mdAndUp"
          cols="3"
        >
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" class="text-center">
          <h3 class="font-weight-regular mb-3">Have questions before your visit? Feel free to send us a message.</h3>
          <v-btn
            large
            rounded
            href="/about#getintouch"
            elevation="4"
            color="#F44D40"
            class="px-8 font-weight-regular"
          >
            <div style="color:white">GET IN TOUCH</div>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: 'AboutNew'
}
</script>
