<template>
  <div style="background-color:#425C80">
    <v-container class="py-15">
      <v-row
        align="center"
        justify="center"
      >
        <v-col
          cols="12"
          class="text-center"
        >
          <h1 class="text-h4 mb-4 font-weight-regular" style="color:white">
            Would you like prayer? Send us your prayer request.          </h1>
          <v-btn
            large
            rounded
            elevation="4"
            color="#F44D40"
            class="px-10 font-weight-regular"
          >
            <div style="color:white">SEND PRAYER REQUEST</div>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: 'AdultsPrayer'
}
</script>
