import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VImg,{attrs:{"dark":"","src":require("@/assets/images/watch/DecisionCardBG.jpg")}},[_c(VOverlay,{attrs:{"absolute":"","opacity":"0.1"}},[_c(VContainer,[_c(VRow,{attrs:{"align":"center","justify":"center"}},[_c(VCol,{staticClass:"text-center",attrs:{"cols":"12"}},[_c('h1',{staticClass:"text-h4 mb-6 font-weight-regular"},[_vm._v(" Thinking about making a decision for Christ? ")]),_c(VBtn,{staticClass:"px-4 font-weight-regular",attrs:{"large":"","rounded":"","elevation":"4","color":"#F44D40"}},[_c('div',{staticStyle:{"color":"white"}},[_vm._v("COMPLETE A DECISION CARD")])])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }