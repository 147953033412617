<template>
  <div style="background-color:#EFEFEF">
    <v-container class="py-12">
      <v-row class="text-center">
        <v-col cols="12">
          <h1 class="display-2 mb-4 mt-0 font-weight-bold text-center" style="color:#707070">
            PAST EVENTS
          </h1>
        </v-col>
      </v-row>
      <WomensBrunch />
      <TrackField />
      <ServiceDay />
    </v-container>
  </div>
</template>

<script>
import WomensBrunch from '@/components/events/WomensBrunch.vue'
import TrackField from '@/components/events/TrackField.vue'
import ServiceDay from '@/components/events/ServiceDay.vue'

export default {
  name: 'EventsPast',
  components: {
    WomensBrunch,
    TrackField,
    ServiceDay
  }
}
</script>
