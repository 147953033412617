import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"background-color":"#EFEFEF"}},[_c(VContainer,[_c(VRow,{staticClass:"mb-10"},[_c(VCol,{attrs:{"cols":"1"}}),_c(VCol,{attrs:{"cols":"10"}},[_c('h1',{staticClass:"mb-0 font-weight-bold",staticStyle:{"color":"#707070"}},[_vm._v("CYSA Track & Field Event")]),_c('h3',{staticClass:"font-weight-regular mb-3",staticStyle:{"color":"#707070"}},[_vm._v("June 07, 2019")]),_c(VRow,_vm._l((15),function(n){return _c(VCol,{key:n,staticClass:"d-flex child-flex",attrs:{"cols":"2"}},[_c(VImg,{staticClass:"grey lighten-2",attrs:{"src":("https://picsum.photos/500/300?image=" + (n * 5 + 10)),"lazy-src":("https://picsum.photos/10/6?image=" + (n * 5 + 10)),"aspect-ratio":"1"},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c(VRow,{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c(VProgressCircular,{attrs:{"indeterminate":"","color":"grey lighten-5"}})],1)]},proxy:true}],null,true)})],1)}),1),_c(VRow,[_c(VCol,{staticClass:"text-center",attrs:{"cols":"12"}},[_c(VBtn,{staticClass:"px-10 font-weight-regular",attrs:{"large":"","rounded":"","elevation":"4","color":"#425C80"}},[_c('div',{staticStyle:{"color":"white"}},[_vm._v("SEE MORE")])])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }