<template>
  <div style="background-image: url('/images/about/AboutBanner.jpg'); background-size:cover; background-position:center; color: white;" class="pt-6">
    <v-container>
      <v-row
        justify="left"
      >
        <v-col 
          v-if="$vuetify.breakpoint.smAndUp"
          cols="2"
          class="mr-8"
        >
        </v-col>
        <v-col
          xs12
          sm7
        >
          <h1 class="display-2 mb-4 mt-15 font-weight-bold text-left">
            WHO WE ARE
          </h1>
          <h3 class="font-weight-regular mb-3">
            At Toronto Central, fellowship is of utmost importance to us. The smiles, the hugs, and the warm greetings at the doors of our sanctuary from our exceptional team of ushers truly make you feel welcome and at home.
          </h3>
          <h3 class="font-weight-regular mb-3">
            Fellowship is so important to us that we have made the effort to create a special committee with the sole responsibility of planning the church’s fellowship activities. Yes that’s how serious we are about fellowship at TC.
          </h3>
          <h3 class="font-weight-regular mb-10">
            To learn more about ministries that serve you, click the link below.
          </h3>
          <!-- <v-btn
            large
            rounded
            elevation="4"
            color="#F44D40"
            class="px-8 font-weight-regular"
          >
            <div style="color:white">VIEW OUR MINISTRIES</div>
          </v-btn> -->
        </v-col>
        <v-col 
          v-if="$vuetify.breakpoint.smAndUp"
          cols="3"
        >
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: 'AboutParallax'
}
</script>
