<template>
  <div>
    <HomeParallax />
    <EventBanners />
    <HomeLive id="live" />
    <HomeAbout />
    <!-- <GetConnected /> -->
    <GetInTouch />
  </div>
</template>

<script>
import HomeParallax from '@/components/home/HomeParallax.vue'
import EventBanners from '@/components/EventBanners.vue'
import HomeLive from '@/components/home/HomeLive.vue'
import HomeAbout from '@/components/home/HomeAbout.vue'
// import GetConnected from '@/components/GetConnected.vue'
import GetInTouch from '@/components/footer/GetInTouch.vue'

export default {
  components: {
    HomeParallax,
    EventBanners,
    HomeLive,
    HomeAbout,
    // GetConnected,
    GetInTouch
  },
  head: {
    title: function () {
      return {
        inner: 'Home'
      }
    }
  }
}
</script>
