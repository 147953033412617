import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VImg,{attrs:{"light":"","src":require("@/assets/images/sports/CYSABG.jpg")}},[_c(VOverlay,{attrs:{"absolute":"","opacity":"0.2"}},[_c(VContainer,[_c('br'),_c('br'),_c('br'),_c('br'),_c('br'),_c('br'),_c('br'),_c('br'),_c('br'),_c('br'),_c('br'),_c('br'),_c('br'),_c('br'),_c('br'),_c('br'),_c('br'),_c('br'),_c('br'),_c('br'),_c('br'),_c('br'),_c(VRow,[_c(VCol,{attrs:{"cols":"5"}}),_c(VCol,{attrs:{"cols":"7"}},[_c(VCard,{staticStyle:{"background-color":"white"},attrs:{"elevation":"2","shaped":""}},[_c(VContainer,[_c(VCardTitle,{staticClass:"text-h4",staticStyle:{"color":"#425C80"}},[_vm._v("CYSA Track & Field")]),_c(VCardText,{staticStyle:{"color":"#425C80"}},[_vm._v("The Pathfinder Club is a department of the SDA church which caters to the cultural, social, physical and most important of all the spiritual needs of children between the age of 10-16. Our activities include Camping, Drilling and Marching, Life Skills, Fun Games, Bible Experience.")]),_c(VCardText,{staticStyle:{"color":"#425C80"}},[_vm._v("If you would like to learn more, please feel free to contact the Pathfinder leader, contact information below. Or feel free to sign up you child for Pathfinders today!")]),_c(VBtn,{staticStyle:{"background-color":"#425C80"},attrs:{"icon":"","href":"/","target":"_blank"}},[_c(VIcon,{attrs:{"color":"white"}},[_vm._v("mdi-email")])],1),_c('span',{staticStyle:{"color":"#425C80"}},[_c('a',{attrs:{"href":"mailto:tcaadventurers@torontocentralsda.com"}},[_vm._v(" tcaadventurers@torontocentralsda.com")])]),_c('br'),_c(VBtn,{staticClass:"px-8 mt-2 font-weight-regular",attrs:{"large":"","rounded":"","elevation":"2","color":"#425C80"}},[_c('div',{staticStyle:{"color":"white"}},[_vm._v("REGISTER")])])],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }