<template>
  <div style="background-image: url('/images/about/BeliefsBG.jpg'); background-size:cover; background-position:center; color: white;" class="pt-6">
       
    <h1 class="display-2 mb-4 mt-0 font-weight-bold text-center">
      OUR BELIEFS
    </h1>

    <v-container v-if="$vuetify.breakpoint.mdAndUp">
      <v-row
        justify="center"
      >
        <v-col cols="2"></v-col>
        <v-col
          cols="8"
        >
          <h3 class="font-weight-regular mb-3">
            Seventh-day Adventists accept the Bible as their only creed and hold certain fundamental beliefs to be the teaching of the Holy Scriptures. These beliefs, as set forth here, constitute the church’s understanding and expression of the teaching of Scripture. 
            <br /><br />
            Revision of these statements may be expected at a quinquennial General Conference Session whenever the church is led by the Holy Spirit to a fuller understanding of Bible truth, or if better language is found to express these teachings of God’s Holy Word. 
            <br /><br />
            The expression of these concepts help provide an overall picture of what this Christian denomination collectively believes and practices. Together, these teachings reveal a God who is the architect of the world. In wisdom, grace and infinite love, He is actively working to restore a relationship with humanity that will last for eternity.
          </h3>
        </v-col>
        <v-col cols="2"></v-col>
      </v-row>

      <v-row>
        <v-col cols="12" class="text-center">
          <v-btn
            large
            rounded
            href="https://www.adventist.org/beliefs"
            target="_blank"
            elevation="4"
            color="#F44D40"
            class="px-10 font-weight-regular"
          >
            <div style="color:white">LEARN MORE</div>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  
    <v-container v-if="$vuetify.breakpoint.smAndDown">
      <v-row
        justify="center"
      >
        <v-col cols="2"></v-col>
        <v-col
          cols="8"
        >
          <h3 class="font-weight-regular mb-3">
            Seventh-day Adventists accept the Bible as their only creed and hold certain fundamental beliefs to be the teaching of the Holy Scriptures. These beliefs, as set forth here, constitute the church’s understanding and expression of the teaching of Scripture. 
            <br /><br />
            Revision of these statements may be expected at a quinquennial General Conference Session whenever the church is led by the Holy Spirit to a fuller understanding of Bible truth, or if better language is found to express these teachings of God’s Holy Word. 
            <br /><br />
            The expression of these concepts help provide an overall picture of what this Christian denomination collectively believes and practices. Together, these teachings reveal a God who is the architect of the world. In wisdom, grace and infinite love, He is actively working to restore a relationship with humanity that will last for eternity.
          </h3>
        </v-col>
        <v-col cols="2"></v-col>
      </v-row>

      <v-row>
        <v-col cols="12" class="text-center">
          <v-btn
            large
            rounded
            href="https://www.adventist.org/beliefs"
            target="_blank"
            elevation="4"
            color="#F44D40"
            class="px-10 font-weight-regular"
          >
            <div style="color:white">LEARN MORE</div>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </div> 
</template>

<script>
export default {
  name: 'AboutBeliefs'
}
</script>
