<template>
  <div>
    <ChildrensAdventurers />
    <ChildrensVBS />
    <ChildrensChoir />
    <ChildrensResources />
    <ChildrensStudy />
    <GetInTouch />
    <YouthStudy />
  </div>
</template>

<script>
import ChildrensAdventurers from '@/components/childrens/ChildrensAdventurers.vue'
import ChildrensVBS from '@/components/childrens/ChildrensVBS.vue'
import ChildrensChoir from '@/components/childrens/ChildrensChoir.vue'
import ChildrensResources from '@/components/childrens/ChildrensResources.vue'
import ChildrensStudy from '@/components/childrens/ChildrensStudy.vue'
import GetInTouch from '@/components/footer/GetInTouch.vue'
import YouthStudy from '@/components/youth/YouthStudy.vue'

export default {
  components: {
    ChildrensAdventurers,
    ChildrensVBS,
    ChildrensChoir,
    ChildrensResources,
    ChildrensStudy,
    GetInTouch,
    YouthStudy
  },
  head: {
    title: function () {
      return {
        inner: 'Childrens'
      }
    }
  }
}
</script>
