<template>
  <v-img
    dark
    src="@/assets/images/watch/DecisionCardBG.jpg"
  >
    <v-overlay absolute opacity="0.1">
      <v-container>
        <v-row
          align="center"
          justify="center"
        >
          <v-col
            cols="12"
            class="text-center"
          >
            <h1 class="text-h4 mb-6 font-weight-regular">
              Thinking about making a decision for Christ?
            </h1>
            <v-btn
              large
              rounded
              elevation="4"
              color="#F44D40"
              class="px-4 font-weight-regular"
            >
              <div style="color:white">COMPLETE A DECISION CARD</div>
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-overlay>
  </v-img>
</template>

<script>
export default {
  name: 'WatchResources'
}
</script>
