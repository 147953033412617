<template>
  <div>
    <WatchParallax />
    <WatchDecision />
    <WatchLatest />
    <GetInTouch />
  </div>
</template>

<script>
import WatchParallax from '@/components/watch/WatchParallax.vue'
import WatchDecision from '@/components/watch/WatchDecision.vue'
import WatchLatest from '@/components/watch/WatchLatest.vue'
import GetInTouch from '@/components/footer/GetInTouch.vue'

export default {
  components: {
    WatchParallax,
    WatchDecision,
    WatchLatest,
    GetInTouch
  },
  head: {
    title: function () {
      return {
        inner: 'Watch'
      }
    }
  }
}
</script>
