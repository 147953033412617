<template>
  <div style="background-color:#EFEFEF">
    <v-container class="py-10">
      <h1 class="display-2 mb-7 font-weight-bold text-center" style="color:#707070">
        WATCH THE LATEST SERMONS
      </h1>
      <v-row
        class="mb-10"
      >
        <v-col cols="1"></v-col>
        <v-col cols="10">
          <v-row>
            <v-col
              v-for="n in 9"
              :key="n"
              class="d-flex child-flex"
              cols="4"
            >
              <v-img
                :src="`https://picsum.photos/500/300?image=${n * 5 + 10}`"
                :lazy-src="`https://picsum.photos/10/6?image=${n * 5 + 10}`"
                aspect-ratio="1"
                class="grey lighten-2"
              >
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      indeterminate
                      color="grey lighten-5"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="text-center">
              <v-btn
                large
                rounded
                elevation="4"
                color="#F44D40"
                class="px-10 font-weight-regular"
                href="https://www.youtube.com/channel/UCuOF0pHZU_3mIKzAz46ESAw"
                target="_blank"
              >
                <div style="color:white">WATCH MORE SERMONS</div>
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: 'WatchLatest'
}
</script>
