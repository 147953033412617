<template>
  <div style="background-color:#707070">
    <v-container>
      <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
      <v-row>
        <v-col cols="7">
          <v-card
            elevation="2"
            shaped
            style="background-color:white"
          >
            <v-container>
              <v-card-title class="text-h4" style="color:#425C80">VACATION BIBLE SCHOOL (VBS)</v-card-title>
              <v-card-text style="color:#425C80">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.</v-card-text>
              <v-btn
                icon
                href="/"
                style="background-color:#425C80"
                target="_blank"
              >
                <v-icon color="white">mdi-email</v-icon>
              </v-btn>
              <span style="color:#425C80"><a href="mailto:vbs@torontocentralsda.com"> vbs@torontocentralsda.com</a></span>
              <br />
              <v-btn
                large
                rounded
                elevation="2"
                color="#425C80"
                class="px-8 mt-2 font-weight-regular"
              >
                <div style="color:white">SEE MORE PICTURES</div>
              </v-btn>
            </v-container>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: 'ChildrensVBS'
}
</script>

<style>
  a {
    text-decoration: none;
  }
</style>
