<template>
  <div style="background-color:#B9B9B9">
    <v-container>
      <v-row>
        <v-col cols="5" class="text-center">
          <v-card
            elevation="2"
            shaped
            style="background-color:white"
          >
            <v-container>
              <v-card-subtitle class="text-h4" style="color:#425C80">ADULT VIRTUAL<br /> SABBATH SCHOOL</v-card-subtitle>
              <v-card-text class="text-h6 font-weight-bold mb-0" style="color:#425C80">Ages 26+<br /> Saturdays @ 9:45 AM</v-card-text>
              <v-card-text style="color:#425C80">Together they are the proud parents of two young adult children, daughter Jode-Leigh and son Roshane, who by God’s grace are doing well for themselves.</v-card-text>
              <v-btn
                large
                rounded
                elevation="2"
                color="#425C80"
                class="px-8 font-weight-regular"
              >
                <div style="color:white">JOIN NOW</div>
              </v-btn>
            </v-container>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="5"></v-col>
        <v-col cols="7">
          <v-card
            elevation="2"
            shaped
            style="background-color:white"
          >
            <v-container>
              <v-card-title class="text-h4" style="color:#425C80">GOSPEL CHOIR</v-card-title>
              <v-card-text style="color:#425C80">The Pathfinder Club is a department of the SDA church which caters to the cultural, social, physical and most important of all the spiritual needs of children between the age of 10-16. Our activities include Camping, Drilling and Marching, Life Skills, Fun Games, Bible Experience.</v-card-text>
              <v-card-text style="color:#425C80">If you would like to learn more, please feel free to contact the Pathfinder leader, contact information below. Or feel free to sign up you child for Pathfinders today!</v-card-text>
              <v-btn
                icon
                href="/"
                style="background-color:#425C80"
                target="_blank"
              >
                <v-icon color="white">mdi-email</v-icon>
              </v-btn>
              <span style="color:#425C80"><a href="mailto:tauruspathfinders@torontocentralsda.com"> tauruspathfinders@torontocentralsda.com</a></span>
              <br />
              <v-btn
                large
                rounded
                elevation="2"
                color="#425C80"
                class="px-8 mt-2 font-weight-regular"
              >
                <div style="color:white">REGISTER</div>
              </v-btn>
            </v-container>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: 'AdultsHeader'
}
</script>

<style>
  a {
    text-decoration: none;
  }
</style>
