<template>
  <div>
    <TopParallax
      title="PRIVACY STATEMENT"
      :img-src="imgSrc"
    />
    <PrivacyContent />
  </div>
</template>

<script>
import TopParallax from '@/components/TopParallax.vue'
import PrivacyContent from '@/components/PrivacyContent.vue'

export default {
  components: {
    TopParallax,
    PrivacyContent
  },
  data() {
    return {
      imgSrc: require('@/assets/images/placeholder.jpg')
    }
  },
  head: {
    title: function () {
      return {
        inner: 'Privacy'
      }
    }
  }
}
</script>
