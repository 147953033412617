<template>
  <v-img
    dark
    src="@/assets/images/missions/ADRABG.jpg"
  >
    <v-overlay absolute opacity="0.1">
      <v-container>
        <v-row
          align="left"
          justify="left"
        >
          <v-col cols="2" class="mr-8"></v-col>
          <v-col
            cols="7"
          >
            <h1 class="display-2 mb-4 mt-15 font-weight-bold text-left">
              ADRA
            </h1>
            <h3 class="font-weight-regular mb-3">
              ADVENTIST DISASTER RELIEF AGENCY
            </h3>
            <h3 class="font-weight-regular mb-3">
              Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore.            </h3>
            <v-btn
              large
              rounded
              elevation="4"
              color="#F44D40"
              class="px-8 font-weight-regular"
            >
              <div style="color:white">GIVE</div>
            </v-btn>
          </v-col>
          <v-col cols="3"></v-col>
        </v-row>
      </v-container>
    </v-overlay>
  </v-img>
</template>

<script>
export default {
  name: 'MissionsADRA'
}
</script>
