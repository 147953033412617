import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VImg,{attrs:{"light":"","src":require("@/assets/images/ministries/FamilyBG.jpg")}},[_c(VOverlay,{attrs:{"absolute":"","opacity":"0.2"}},[_c(VContainer,[_c('br'),_c('br'),_c('br'),_c('br'),_c('br'),_c('br'),_c('br'),_c('br'),_c('br'),_c('br'),_c('br'),_c('br'),_c('br'),_c('br'),_c('br'),_c('br'),_c('br'),_c('br'),_c('br'),_c('br'),_c('br'),_c('br'),_c(VRow,[_c(VCol,{attrs:{"cols":"5"}}),_c(VCol,{attrs:{"cols":"7"}},[_c(VCard,{staticStyle:{"background-color":"white"},attrs:{"elevation":"2","shaped":""}},[_c(VContainer,[_c(VCardTitle,{staticClass:"text-h4",staticStyle:{"color":"#425C80"}},[_vm._v("FAMILY MINISTRIES")]),_c(VCardText,{staticStyle:{"color":"#425C80"}},[_vm._v("Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.")]),_c(VBtn,{staticStyle:{"background-color":"#425C80"},attrs:{"icon":"","href":"/","target":"_blank"}},[_c(VIcon,{attrs:{"color":"white"}},[_vm._v("mdi-email")])],1),_c('span',{staticStyle:{"color":"#425C80"}},[_c('a',{attrs:{"href":"mailto:youthmissiontrip@torontocentralsda.com"}},[_vm._v(" youthmissiontrip@torontocentralsda.com")])]),_c('br'),_c(VBtn,{staticClass:"px-8 mt-2 font-weight-regular",attrs:{"large":"","rounded":"","elevation":"2","color":"#425C80"}},[_c('div',{staticStyle:{"color":"white"}},[_vm._v("SEE MORE PICTURES")])])],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }