<template>
  <div style="background-color:#425C80">
    <v-container>
      <h1 class="display-2 mb-15 mt-15 font-weight-bold text-center" style="color:white">
        CHILDREN & YOUTH RESOURCES
      </h1>
      <v-row
        align="center"
        justify="center"
      >
        <v-col cols="1"></v-col>
        <v-col
          cols="3"
          style="background-color:#EFEFEF"
          class="text-center mb-15 mr-2"
        >
          <h4 style="color:#425C80" class="py-12">GRACELINK</h4>
        </v-col>
        <v-col
          cols="3"
          style="background-color:#EFEFEF"
          class="text-center mb-15 mr-2"
        >
          <h4 style="color:#425C80" class="py-12">3ABN KIDS NETWORK</h4>
        </v-col>
        <v-col
          cols="3"
          style="background-color:#EFEFEF"
          class="text-center mb-15"
        >
          <h4 style="color:#425C80" class="py-12">GUIDE MAGAZINE</h4>
        </v-col>
        <v-col cols="1"></v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: 'ChildrensResources'
}
</script>
