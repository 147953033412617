<template>
  <div>
    <DecisionForChrist />
  </div>
</template>
    
<script>
import DecisionForChrist from '@/components/DecisionForChrist.vue'

export default {
  components: {
    DecisionForChrist
  },
  head: {
    title: function () {
      return {
        inner: 'Decision For Christ'
      }
    }
  }
}
</script>