<template>
  <div>
    <v-overlay :value="loading">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>

    <v-form v-model="valid">
      <v-container>
        <v-row>
          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              v-model="firstname"
              :rules="nameRules"
              label="First Name"
              required
              dark 
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              v-model="lastname"
              :rules="nameRules"
              label="Last Name"
              required
              dark 
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            md="12"
          >
            <v-text-field
              v-model="email"
              :rules="emailRules"
              label="Email Address"
              required
              dark 
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-select
              v-model="selectedOption"
              :items="selectOptions"
              :rules="selectRules"
              dark
              label="Would you like someone to contact you?"
              required
              @change="$v.select.$touch()"
              @blur="$v.select.$touch()"
            ></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            md="12"
          >
            <v-text-field
              v-model="message"
              label="Message"
              dark 
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="text-right">
            <v-btn
              id="intouch-send"
              :disabled="!valid"
              large
              rounded
              class="px-10 font-weight-regular"
              color="secondary"
              @click="sendEmail"
            >
              <div style="color:white">SEND</div>
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </div>
</template>

<script>

import axios from 'axios'
import Vue from 'vue'
import VueToast from 'vue-toast-notification'
import 'vue-toast-notification/dist/theme-sugar.css'
Vue.use(VueToast)

const instance = axios.create({
  baseURL: process.env.VUE_APP_EMAIL_API_URL,
  timeout: 50000,
  headers: {
    Accept: '*',
    'Content-Type': 'application/x-www-form-urlencoded',
    'Ocp-Apim-Subscription-Key': process.env.VUE_APP_EMAIL_API_KEY
  }
})

export default {
  name: 'PrayerRequestForm',
  data: () => ({
    valid: false,
    selectOptions: [
      'Yes',
      'No'
    ],
    firstname: '',
    lastname: '',
    nameRules: [
      (v) => !!v || 'Name is required'
    ],
    email: '',
    emailRules: [
      (v) => !!v || 'E-mail is required',
      (v) => /.+@.+/.test(v) || 'E-mail must be valid'
    ],
    subject: 'Prayer Request',
    subjectRules: [
      (v) => !!v || 'Subject is required'
    ],
    message: '',
    messageRules: [
      (v) => !!v || 'Message is required'
    ],    
    selectedOption: '',
    selectRules: [
      (v) => !!v || 'Select an option'
    ],    
    loading: false
  }),
  methods: {
    async sendEmail() {

      this.loading = true

      const formData = new FormData()

      formData.append('senderName', `${this.firstname} ${this.lastname}`)
      formData.append('senderEmail', 'info@torontocentralsda.com')
      formData.append('recipients', 'tcprayer@torontocentralsda.com')
      formData.append('subject', this.subject)

      let emailBody = `Name: ${this.firstname} ${this.lastname}\r\n`

      emailBody += `Email: ${this.email}\r\n`
      emailBody += `Subject: ${this.subject}\r\n`
      emailBody += `Would you like someone to contact you?: ${this.selectedOption}\r\n`
      emailBody += `Message: ${this.message}\r\n`

      formData.append('emailBody', emailBody)

      axios ({
        method: 'post',
        data: formData,
        url: process.env.VUE_APP_EMAIL_API_URL,
        headers: {
          Accept: '*',
          'Content-Type': 'application/x-www-form-urlencoded',
          'Ocp-Apim-Subscription-Key': process.env.VUE_APP_EMAIL_API_KEY
        }
      })
        .then((response) => {
          this.loading = false
          this.resetForm()
          this.successToast()
        })
        .catch((error) => {
          console.log(error)
          this.failedToast()
        })
        .finally(() => this.loading = false)
    },
    resetForm() {
      this.fullname = ''
      this.email = ''
      // this.subject = ''
      this.message = ''
      this.selectedOption = ''
    },
    successToast() {
      Vue.$toast.open({
        message: 'Message successfully sent',
        type: 'success',
        position: 'top-right',
        duration: 7000
      })
    },
    failedToast() {
      Vue.$toast.open({
        message: 'Sorry, we were unable to send your message!',
        type: 'error',
        position: 'top-right',
        duration: 7000
      })
    }
  }
}
</script>

<style>
#intouch-send.v-btn--disabled {
    background-color: #ffffff20 !important;
}

.text-input-white .v-text-field__slot input {
   color: white !important;
}
</style>
