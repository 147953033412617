<template>
  <div>
    <OtherResources />
    <EventsStudy />
    <GetInTouch />
  </div>
</template>

<script>
import OtherResources from '@/components/resources/OtherResources.vue'
import EventsStudy from '@/components/events/EventsStudy.vue'
import GetInTouch from '@/components/footer/GetInTouch.vue'

export default {
  components: {
    OtherResources,
    EventsStudy,
    GetInTouch
  },
  head: {
    title: function () {
      return {
        inner: 'Resources'
      }
    }
  }
}
</script>
