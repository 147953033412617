<template>
  <v-img
    light
    src="@/assets/images/resources/ResourcesBG.jpg"
  >
    <v-overlay absolute opacity="0.2">
      <v-container>
        <v-row>
          <v-col cols="12">
            <h1 class="display-2 font-weight-bold text-center" style="color:white">
              OTHER RESOURCES
            </h1>
          </v-col>
        </v-row>
        <v-row
          align="center"
          justify="center"
        >
          <v-col cols="1"></v-col>
          <v-col
            cols="3"
            style="background-color:#EFEFEF"
            class="text-center mb-15 mr-2"
          >
            <h4 style="color:#425C80" class="py-12">ONTARIO CONFERENCE OF SEVENTH-DAY ADENTISTS</h4>
          </v-col>
          <v-col
            cols="3"
            style="background-color:#EFEFEF"
            class="text-center mb-15 mr-2"
          >
            <h4 style="color:#425C80" class="py-12">CANADIAN UNION OF SEVENTH-DAY ADVENTISTS</h4>
          </v-col>
          <v-col
            cols="3"
            style="background-color:#EFEFEF"
            class="text-center mb-15"
          >
            <h4 style="color:#425C80" class="py-12">NORTH AMERICAN DIVISION</h4>
          </v-col>
          <v-col cols="1"></v-col>
        </v-row>
        <v-row
          align="center"
          justify="center"
        >
          <v-col cols="1"></v-col>
          <v-col
            cols="3"
            style="background-color:#EFEFEF"
            class="text-center mb-15 mr-2"
          >
            <h4 style="color:#425C80" class="py-12">GENERAL CONFERENCE OF SEVENTH-DAY ADVENTISTS</h4>
          </v-col>
          <v-col
            cols="3"
            style="background-color:#EFEFEF"
            class="text-center mb-15 mr-2"
          >
            <h4 style="color:#425C80" class="py-12">SABBATH SCHOOL: QUARTERLY BIBLE STUDY RESOURCES</h4>
          </v-col>
          <v-col
            cols="3"
            style="background-color:#EFEFEF"
            class="text-center mb-15"
          >
            <h4 style="color:#425C80" class="py-12">SABBATH SCHOOL NET: CURRENT ADULT SABBATH SCHOOL LESSON</h4>
          </v-col>
          <v-col cols="1"></v-col>
        </v-row>
        <v-row
          align="center"
          justify="center"
        >
          <v-col cols="1"></v-col>
          <v-col
            cols="3"
            style="background-color:#EFEFEF"
            class="text-center mb-15 mr-2"
          >
            <h4 style="color:#425C80" class="py-12">EDUCATION: THE INSITUTE FOR CHRISTIAN TEACHING</h4>
          </v-col>
          <v-col
            cols="3"
            style="background-color:#EFEFEF"
            class="text-center mb-15 mr-2"
          >
            <h4 style="color:#425C80" class="py-12">EDUCATION: ADVENTIST AAA ACCREDITED COLLEGES AND UNIVERSITIES</h4>
          </v-col>
          <v-col
            cols="3"
            style="background-color:#EFEFEF"
            class="text-center mb-15"
          >
            <h4 style="color:#425C80" class="py-12">MUSIC STATION: WJOU 90.1 FM</h4>
          </v-col>
          <v-col cols="1"></v-col>
        </v-row>
        <v-row
          align="center"
          justify="center"
        >
          <v-col cols="1"></v-col>
          <v-col
            cols="3"
            style="background-color:#EFEFEF"
            class="text-center mb-15 mr-2"
          >
            <h4 style="color:#425C80" class="py-12">MUSIC STATION: NCU MEDIA GROUP</h4>
          </v-col>
          <v-col
            cols="3"
            style="background-color:#EFEFEF"
            class="text-center mb-15 mr-2"
          >
            <h4 style="color:#425C80" class="py-12">MUSIC STATION: WAUS 90.7 FM</h4>
          </v-col>
          <v-col
            cols="3"
            style="background-color:#EFEFEF"
            class="text-center mb-15"
          >
            <h4 style="color:#425C80" class="py-12">PUBLICATION: CANADIAN ADVENTIST MESSENGER</h4>
          </v-col>
          <v-col cols="1"></v-col>
        </v-row>
        <v-row
          align="center"
          justify="center"
        >
          <v-col cols="1"></v-col>
          <v-col
            cols="3"
            style="background-color:#EFEFEF"
            class="text-center mb-15 mr-2"
          >
            <h4 style="color:#425C80" class="py-12">PUBLICATION: ADVENTIST REVIEW</h4>
          </v-col>
          <v-col
            cols="3"
            style="background-color:#EFEFEF"
            class="text-center mb-15 mr-2"
          >
            <h4 style="color:#425C80" class="py-12">PUBLICATION: LIBERTY MAGAZINE</h4>
          </v-col>
          <v-col
            cols="3"
            style="background-color:#EFEFEF"
            class="text-center mb-15"
          >
            <h4 style="color:#425C80" class="py-12">PUBLICATION: SIGNS OF THE TIMES</h4>
          </v-col>
          <v-col cols="1"></v-col>
        </v-row>
      </v-container>
    </v-overlay>
  </v-img>
</template>

<script>
export default {
  name: 'OtherResources'
}
</script>
