<template>
  <div style="background-image: url('/images/newPlaceholder.jpg'); background-size:cover; background-position:center;">
    <div style="background-color: #00000050" class="pt-6">
      <v-container>
        <div
          style="text-align:center;"
        >
          <h1 class="text-h3" style="color:white">GET IN TOUCH</h1>
        </div>
        <v-row>
          <v-col cols="12" md="12" class="text-center">
            <v-btn
              class="mx-2"
              fab
              dark
              small
              color="#425C80"
            >
              <v-icon dark>
                mdi-map-marker-outline
              </v-icon>
            </v-btn>
            <span color="white" class="text-h6">
              <a href="https://www.google.com/maps/dir//162+Bentworth+Ave,+North+York,+ON+M6A+1P7,+Canada" style="color:white" target="_blank">
                162 Bentworth Ave., North York, Ontario M6A 1P7 (get directions)
              </a>
            </span>
            <br />
            <v-btn
              class="mx-2"
              fab
              dark
              small
              color="#425C80"
            >
              <v-icon dark>
                mdi-phone-outline
              </v-icon>
            </v-btn>
            <span color="primary" class="text-h6">
              <a href="tel:416-784-0261" style="color:white">
                416-784-0261
              </a>
            </span>
            <br />
            <v-btn
              class="mx-2"
              fab
              dark
              small
              color="#425C80"
            >
              <v-icon dark>
                mdi-email-outline
              </v-icon>
            </v-btn>
            <span color="primary" class="text-h6">
              <a href="mailto:contactus@torontocentralsda.com" style="color:white">
                contactus@torontocentralsda.com
              </a>
            </span>
          </v-col>
        </v-row>
        <GetInTouchForm />
      </v-container>
    </div>
  </div>
</template>

<script>
import GetInTouchForm from '@/components/footer/GetInTouchForm.vue'
export default {
  name: 'GetInTouch',
  components: {
    GetInTouchForm
  }
}
</script>

<style>
  a {
    text-decoration: none;
  }
</style>
