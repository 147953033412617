<template>
  <div style="background-color:#EFEFEF">
    <v-container>
      <v-row
        class="mb-10"
      >
        <v-col cols="1"></v-col>
        <v-col cols="10">
          <h1 class="mb-0 font-weight-bold" style="color:#707070">Community Service Day</h1>
          <h3 class="font-weight-regular mb-3" style="color:#707070">August 20, 2019</h3>
          <v-row>
            <v-col
              v-for="n in 15"
              :key="n"
              class="d-flex child-flex"
              cols="2"
            >
              <v-img
                :src="`https://picsum.photos/500/300?image=${n * 5 + 10}`"
                :lazy-src="`https://picsum.photos/10/6?image=${n * 5 + 10}`"
                aspect-ratio="1"
                class="grey lighten-2"
              >
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      indeterminate
                      color="grey lighten-5"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="text-center">
              <v-btn
                large
                rounded
                elevation="4"
                color="#425C80"
                class="px-10 font-weight-regular"
              >
                <div style="color:white">SEE MORE EVENT PHOTOS</div>
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: 'ServiceDay'
}
</script>
