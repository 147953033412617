import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VImg,{attrs:{"dark":"","src":require("@/assets/images/watch/WatchHeader.jpg")}},[_c(VOverlay,{attrs:{"absolute":"","opacity":"0.1"}},[_c(VContainer,[_c(VRow,{attrs:{"align":"center","justify":"center"}},[_c(VCol,{staticClass:"text-center",attrs:{"cols":"12","elevation-2":""}},[_c('iframe',{attrs:{"width":"720","height":"480","src":"https://www.youtube.com/embed/live_stream?channel=UCuOF0pHZU_3mIKzAz46ESAw&autoplay=1","frameborder":"0"}}),_c('h2',{staticClass:"mt-2 font-wieght-medium"},[_vm._v("FROM BITTER TO BETTER WITH JOEL NEMBHARD")])])],1),_c(VRow,[_c(VCol,{staticClass:"text-center",attrs:{"cols":"12","md":"12"}},[_c(VBtn,{staticClass:"px-10 font-weight-regular",attrs:{"large":"","rounded":"","elevation":"4","color":"#F44D40","href":"https://www.youtube.com/channel/UCuOF0pHZU_3mIKzAz46ESAw","target":"_blank"}},[_c('div',{staticStyle:{"color":"white"}},[_vm._v("VISIT OUR YOUTUBE")])])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }