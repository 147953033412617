<template>
  <div style="background-color:#425C80;background-image: url('/images/DecisionForChrist.png'); background-size:cover; background-position:center;">
    <div class="pt-10">
      <v-container>
        <div
          style="text-align:center;"
        >
          <h1 class="text-h3" style="color:#ffffff90;">Decision For Christ</h1>
        </div>
        <DecisionForChristForm />
      </v-container>
    </div>
  </div>
</template>

<script>
import DecisionForChristForm from '@/components/DecisionForChristForm.vue'
export default {
  name: 'DecisionForChrist',
  components: {
    DecisionForChristForm
  }
}
</script>

<style>
  a {
    text-decoration: none;
  }
</style>
