<template>
  <div style="background-color:#707070">
    <v-container>
      <v-row>
        <v-col cols="1" class="mr-10"></v-col>
        <v-col cols="9">
          <v-card
            elevation="2"
            style="background-color:white"
          >
            <v-container>
              <v-card-title class="text-h3 font-weight-bold" style="color:#425C80">PATHFINDERS & ADVENTURERS<br /> INTEREST FORM</v-card-title>
              <v-card-text class="text-h5 font-weight-bold" style="color:#425C80">Child's Information</v-card-text>
              <v-form v-model="valid">
                <v-container>
                  <v-row>
                    <v-col
                      cols="12"
                      md="6"
                    >
                      <v-text-field
                        v-model="firstname"
                        :rules="nameRules"
                        :counter="10"
                        label="First name"
                        required
                      ></v-text-field>
                    </v-col>

                    <v-col
                      cols="12"
                      md="6"
                    >
                      <v-text-field
                        v-model="lastname"
                        :rules="nameRules"
                        :counter="10"
                        label="Last name"
                        required
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      cols="12"
                      md="12"
                    >
                      <v-text-field
                        v-model="phone"
                        :rules="phoneRules"
                        label="Home Phone"
                        required
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      cols="12"
                      md="12"
                    >
                      <v-text-field
                        v-model="date"
                        :rules="dateRules"
                        label="Date of Birth"
                        required
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
              <v-card-text class="text-h5 font-weight-bold" style="color:#425C80">Parent / Gaurdian’s Information</v-card-text>
              <v-form v-model="valid">
                <v-container>
                  <v-row>
                    <v-col
                      cols="12"
                      md="6"
                    >
                      <v-text-field
                        v-model="firstname"
                        :rules="nameRules"
                        :counter="10"
                        label="First name"
                        required
                      ></v-text-field>
                    </v-col>

                    <v-col
                      cols="12"
                      md="6"
                    >
                      <v-text-field
                        v-model="lastname"
                        :rules="nameRules"
                        :counter="10"
                        label="Last name"
                        required
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      cols="12"
                      md="12"
                    >
                      <v-text-field
                        v-model="email"
                        :rules="emailRules"
                        label="E-mail"
                        required
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      cols="12"
                      md="12"
                    >
                      <v-text-field
                        v-model="phone"
                        :rules="phoneRules"
                        label="Home Phone"
                        required
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
              <v-card-text class="text-h5 font-weight-bold" style="color:#425C80">Please note all information will be kept confidential.</v-card-text>
              <v-btn
                large
                rounded
                elevation="2"
                color="#F44D40"
                class="px-8 mt-2 font-weight-regular"
              >
                <div style="color:white">SUBMIT</div>
              </v-btn>
            </v-container>
          </v-card>
        </v-col>
        <v-col cols="2"></v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: 'FormsPA',
  data: () => ({
    valid: false,
    firstname: '',
    lastname: '',
    nameRules: [
      (v) => !!v || 'Name is required',
      (v) => v.length <= 10 || 'Name must be less than 10 characters'
    ],
    email: '',
    emailRules: [
      (v) => !!v || 'E-mail is required',
      (v) => /.+@.+/.test(v) || 'E-mail must be valid'
    ],
    phone: '',
    phoneRules: [
      (v) => !!v || 'Phone number is required',
      (v) => v.length <= 10 || 'Phone number must be less than 10 characters'
    ],
    date: '',
    dateRules: [
      (v) => !!v || 'Birthday is required'
    ]
  })
}
</script>

<style>
  a {
    text-decoration: none;
  }
</style>
