<template>
  <div style="background-image: url('/images/about/M&MBG.jpg'); background-size:cover; background-position:center; color: white;" class="pt-6">
    <v-container>
      <v-row        
        justify="left"
      >
        <v-col 
          v-if="$vuetify.breakpoint.smAndUp"
          cols="2" 
          class="mr-8"
        >
        </v-col>
        <v-col
          xs12
          sm7
        >
          <h1 class="display-2 mb-4 font-weight-bold text-left">
            OUR MOTTO
          </h1>
          <h3 class="font-weight-regular mb-10">
            Where Jesus is central and people care about people.
          </h3>
          <h1 class="display-2 mb-4 font-weight-bold text-left">
            OUR MISSION
          </h1>
          <h3 class="font-weight-regular">
            To inspire Christ-centered hope and wholeness through prevailing prayer,<br/>
            uplifting worship, transforming witnessing, enriching fellowship, encouraging discipleship, faithful stewardship, and elevating community connections.
          </h3>
        </v-col>
        <v-col
          v-if="$vuetify.breakpoint.smAndUp"
          cols="3"
        >
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: 'MottoMission'
}
</script>
