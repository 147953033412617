<template>
  <div style="background-image: url('/images/home/HomeBackground.png'); background-size:cover; background-position:center; color: white; background-position:top; background-repeat:no-repeat; aspect-ratio: 3840 / 2152;" class="pt-6">
    <v-layout align-center row fill-height>
      <v-container>

        <v-row
          justify="center"
        >

          <v-col
            class="text-center"
            cols="12"
          >
            <h1 class="display-1 mb-0 font-weight-bold">
              FIND JESUS, FIND HOPE, FIND FAMILY
            </h1>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="12" class="text-center">
            <v-btn
              large
              rounded
              elevation="4"
              color="#425C80"
              class="px-10 font-weight-regular"
              href="/about"
            >
              <div style="color:white">LEARN MORE</div>
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-layout>
  </div>
</template>

<script>
export default {
  name: 'HomeParallax'
}
</script>
